import React, { ReactElement } from "react";
import MainBanner from "@components/debtreliefgate/mainBanner/mainBanner";
import { GetServerSideProps } from "next";
import { getPageProps } from "@services/initial-calls";
import { useDomainContext } from "@hooks/useDomainContext";
import DebtReliefGateWrapper from "@components/layouts/debtReliefGateWrapper";
import OffersList from "@components/shared/offersList";
import WhySeekDebtRelief from "@components/debtreliefgate/whySeekDebtRelief";
import DebtReliefMean from "@components/debtreliefgate/debtReliefMean";
import UnlockAdvantages from "@components/debtreliefgate/unlockAdvantages";
export default function DebtReliefGateHome(): ReactElement {
    const { domain } = useDomainContext();

    return (
        <DebtReliefGateWrapper>
            <MainBanner />
            <section style={{ padding: "0 15px" }}>
                <OffersList offers={domain?.defaultOffers} />
            </section>
            <WhySeekDebtRelief />
            <DebtReliefMean />
            <UnlockAdvantages />
        </DebtReliefGateWrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    return await getPageProps(context, "home");
};
